import Vue from 'vue';
import VueRouter, {Route} from 'vue-router';
import DashboardRouter from '../views/DashboardRouter.vue';

import store from '../store';
import {Component, RouteConfig, RouteRecord} from "vue-router/types/router";

Vue.use(VueRouter);

function isLoggedIn() {
  return store.getters['auth/isLoggedIn'];
}

function isAdmin() {
  return store.getters['auth/isAdmin'];
}

function requireAuth(to: Route, from: any, next: any) {
  if (isLoggedIn()) {
    next();
  } else {
    next('/login');
  }
}

function requireAdmin(to: Route, from: any, next: any) {
  if (isLoggedIn()) {
    if (isAdmin()) {
      next();
    } else {
      next('/');
    }
  } else {
    next('/login');
  }
}

function notAllowedAuth(to: Route, from: any, next: any) {
  if (!isLoggedIn()) {
    next();
  } else {
    next('/');
  }
}

type RouteName = "home" | "modpack" | "version" | "notifications" | "permissions" | "Approval Queue" | "Manage Users"
    | "Manage Group" | "Manage Players" | "Manage Group Id" | "Manage Tags" | "View Logs" | "Featured Packs"
    | "Settings" | "logout" | "Login" | "Auth" | "admin"
    | "modpack-meta" | "modpack-desc" | "modpack-art" | "modpack-links" | "modpack-users" | "modpack-players"
    | "modpack-groups" | "modpack-tokens" | "modpack-settings" | "modpack-versions";

const endpoint = (path: string, name: RouteName | "void", component?: Component, children?: RouteConfig[], auth = true, admin = false): RouteConfig => {
  const payload: RouteConfig = {
    path,
    component: component,
  }

  if (children) {
    payload.children = children;
  }

  if (name !== "void") {
    payload.name = name;
  }

  if (auth) {
    payload.beforeEnter = admin ? requireAdmin : requireAuth
  }

  return payload;
}

const adminEndpoint = (path: string, name: RouteName | "void", component?: Component, children?: RouteConfig[]) =>
    endpoint(path, name, component, children, true, true)

const routes: RouteConfig[] = [
  endpoint("/", "void", DashboardRouter, [
    endpoint("",                          "home", () => import(/* webpackChunkName: "home" */ '../views/Home.vue')),
    endpoint("modpack/:id",               "void", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/ModPackView.vue'), [
      endpoint("",                        "modpack-meta", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackMeta.vue')),
      endpoint("description",             "modpack-desc", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackDescription.vue')),
      endpoint("artwork",                 "modpack-art", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackArtwork.vue')),
      endpoint("links",                   "modpack-links", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackLinks.vue')),
      endpoint("users",                   "modpack-users", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackUsers.vue')),
      endpoint("players",                 "modpack-players", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackPlayers.vue')),
      endpoint("groups",                  "modpack-groups", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackGroups.vue')),
      endpoint("tokens",                  "modpack-tokens", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackTokens.vue')),
      endpoint("settings",                "modpack-settings", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackSettings.vue')),
      endpoint("versions",                "modpack-versions", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/tabs/PackVersions.vue')),
      endpoint("version/:vid",            "version", () => import(/* webpackChunkName: "modpacks" */ '../views/modpacks/ModPackVersionView.vue')),
    ]),
    endpoint('notifications',             'notifications', () => import(/* webpackChunkName: "home" */ '../views/NotificationsPage.vue')),
    endpoint('permissions',               'permissions', () => import(/* webpackChunkName: "home" */ '../views/PermissionsPage.vue')),
  ]),

  // Admin area
  adminEndpoint("/admin",                 "void", DashboardRouter, [
    adminEndpoint('approval',             'Approval Queue', () => import(/* webpackChunkName: "admin" */ '../views/ApprovalQueue.vue')),
    adminEndpoint('users',                'Manage Users', () => import(/* webpackChunkName: "admin" */ '../views/ManageUsers.vue')),

    // Groups :D
    adminEndpoint("groups",               "void", () => import(/* webpackChunkName: "admin-groups" */ '../views/groups/GroupsVue.vue'), [
      adminEndpoint('',                   'Manage Group', () => import(/* webpackChunkName: "admin-groups" */ '../views/groups/ManageGroups.vue')),
      adminEndpoint('players',            'Manage Players', () => import(/* webpackChunkName: "admin-groups" */ '../views/groups/ManagePlayers.vue')),
      adminEndpoint(':id',                'Manage Group Id', () => import(/* webpackChunkName: "admin-groups" */ '../views/groups/ManageGroup.vue')),
    ]),

    adminEndpoint('tags',                 'Manage Tags', () => import(/* webpackChunkName: "admin" */ '../views/ManageTags.vue')),
    adminEndpoint('logs',                 'View Logs', () => import(/* webpackChunkName: "admin" */ '../views/ActionLogs.vue')),
    adminEndpoint('featured',             'Featured Packs', () => import(/* webpackChunkName: "admin" */ '../views/FeaturedPacks.vue')),
    adminEndpoint('settings',             'Settings', () => import(/* webpackChunkName: "admin" */ '../views/Settings.vue')),
  ]),

  endpoint('/login',                      'Login', () => import(/* webpackChunkName: "login" */ '../views/Login.vue'), undefined, false),
  endpoint('/auth',                       'Auth', () => import(/* webpackChunkName: "login" */ '../views/AuthRedirect.vue'), undefined, false),

  // Logout is special, so we can keep it messy :D
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to: Route, from: any, next: any) => {
      store.dispatch('auth/logout').catch(console.error);
      return next('/login');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
