import {
    FabricLoaderVersionsResponse, JavaVersionsResponse,
    MinecraftVersionsResponse,
    ModLoaderVersionsResponse,
} from "@core/api";
import {Endpoint} from "@core/api/endpoints";

export class VersionsEndpoint extends Endpoint {
    constructor() {
        super("versions");
    }

    public async getMinecraftVersions() {
        return await this.request<MinecraftVersionsResponse>("GET", "mc");
    }

    public async getForgeVersions(minecraftVersion: string) {
        return await this.request<ModLoaderVersionsResponse>("GET", `forge/${minecraftVersion}`);
    }

    public async getNeoForgeVersions(minecraftVersion: string) {
        return await this.request<ModLoaderVersionsResponse>("GET", `neoforge/${minecraftVersion}`);
    }

    public async getJavaVersions() {
        return await this.request<JavaVersionsResponse>("GET", "java");
    }

    /**
     * TODO: Likely a good idea to move the transforming of this data to a different location
     *
     * @param minecraftVersion
     */
    public async getFabricVersion(minecraftVersion: string): Promise<ModLoaderVersionsResponse | null> {
        // Transform the result as we get a lot of data back
        const fabricVersions = await this.request<FabricLoaderVersionsResponse>("GET", `fabric/${minecraftVersion}`)
        if (!fabricVersions?.success) {
            return {
                success: false,
                versions: []
            }
        }

        return {
            success: true,
            versions: fabricVersions.versions.map(e => e.loader.version)
        };
    }
}
