import {Endpoint} from "@core/api/endpoints";
import {UserSearchResponse} from "@core/api";

export class UsersEndpoint extends Endpoint {
    constructor() {
        super("users");
    }

    public async searchUsers(query: string) {
        const searchParams = new URLSearchParams();
        searchParams.set("search", query);

        return await this.request<UserSearchResponse>("GET", "search?" + searchParams.toString(), {}, false);
    }
}
