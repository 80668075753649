import {ActionTree} from 'vuex';
import {AdminState, LogItem} from './types'
import Vue from 'vue';
import {RootState, UserRole} from '@/types';

export interface DeclineActionData {
  id: number;
  reason: string;
}

interface AddUserData {
  email: string;
  role: UserRole;
}

interface AddGroupData {
  name: string;
  description: string;
}

interface UpdateGroupData {
  id: number;
  name: string;
  description: string;
}

interface UpdateRoleData {
  id: number;
  role: UserRole;
}

interface AddTagData {
  name: string;
  private: boolean;
}

interface UpdateTagData {
  id: number;
  name: string;
  private: boolean;
}

interface AddTeamData {
  name: string;
  website: string;
  logo: string;
}

interface UpdateTeamData {
  id: number;
  name: string;
  website: string;
  logo: string;
}

export const actions: ActionTree<AdminState, RootState> = {
  getApprovalQueue({dispatch, commit, rootState}): any {
    commit('loading');
    return Vue.http.get(`queue`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadQueue', data.items);
        return true;
      } else {
        return false;
      }
    });
  },
  approveQueueItem({dispatch, commit, rootState}, payload: number): any {
    return Vue.http.post(`queue/${payload}/approve`, {}, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getApprovalQueue');
      }
      return data;
    });
  },
  declineQueueItem({dispatch, commit, rootState}, payload: DeclineActionData): any {
    return Vue.http.post(`queue/${payload.id}/decline`,
        {reason: payload.reason},
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getApprovalQueue');
      }
      return data
    });
  },
  getUsers({dispatch, commit, rootState}): any {
    return Vue.http.get(`users`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadUsers', data.items);
        return true;
      } else {
        return false;
      }
    });
  },
  getGroups({dispatch, commit, rootState}): any {
    return Vue.http.get(`groups`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadGroups', data.groups);
        return true;
      } else {
        return false;
      }
    });
  },
  addGroup({dispatch, commit, rootState}, payload: AddGroupData): any {
    return Vue.http.post(`groups`, {
      name: payload.name,
      description: payload.description,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getGroups');
        return data;
      } else {
        return data;
      }
    });
  },
  addPlayerToGroup({dispatch, commit, rootState}, payload: any): any {
    return Vue.http.post(`groups/${payload.id}`, {
      friendCode: payload.friendCode,
      nickName: payload.nickname
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getGroups');
        return data;
      } else {
        return data;
      }
    });
  },
  setActiveGroup({commit, rootState}, payload: number): any {
    commit('setActiveGroup', payload);
  },
  deleteGroup({dispatch, commit, rootState}, id: number): any {
    return Vue.http.delete(`groups/${id}`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getGroups');
        return data;
      } else {
        return data;
      }
    });
  },
  updateGroup({dispatch, commit, rootState}, payload: UpdateGroupData): any {
    return Vue.http.patch(`groups/${payload.id}`, {
      name: payload.name,
      description: payload.description,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getGroups');
        return data;
      } else {
        return data;
      }
    });
  },
  addUser({dispatch, commit, rootState}, payload: AddUserData): any {
    return Vue.http.post(`users`, {
      email: payload.email,
      role: payload.role,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getUsers');
        return data;
      } else {
        return data;
      }
    });
  },
  deleteUser({dispatch, commit, rootState}, id: number): any {
    return Vue.http.delete(`users/${id}`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getUsers');
        return data;
      } else {
        return data;
      }
    });
  },
  updateRole({dispatch, commit, rootState}, payload: UpdateRoleData): any {
    return Vue.http.patch(`users/${payload.id}`,
        {role: payload.role},
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getUsers');
      }
      return data;
    });
  },
  addTag({dispatch, commit, rootState}, payload: AddTagData): any {
    return Vue.http.post(`tags`, {
      name: payload.name,
      private: payload.private,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('cache/getTags', {}, {root: true});
        return data;
      } else {
        return data;
      }
    });
  },
  deleteTag({dispatch, commit, rootState}, id: number): any {
    return Vue.http.delete(`tags/${id}`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('cache/getTags', {}, {root: true});
        return data;
      } else {
        return data;
      }
    });
  },
  updateTag({dispatch, commit, rootState}, payload: UpdateTagData): any {
    return Vue.http.patch(`tags/${payload.id}`, {
      name: payload.name,
      private: payload.private,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('cache/getTags', {}, {root: true});
        return data;
      } else {
        return data;
      }
    });
  },
  addTeam({dispatch, commit, rootState}, payload: AddTeamData): any {
    return Vue.http.post(`teams`, payload, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getTeams');
        return data;
      } else {
        return data;
      }
    });
  },
  deleteTeam({dispatch, commit, rootState}, id: number): any {
    return Vue.http.delete(`teams/${id}`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getTeams');
        return data;
      } else {
        return data;
      }
    });
  },
  updateTeam({dispatch, commit, rootState}, payload: UpdateTeamData): any {
    return Vue.http.patch(`teams/${payload.id}`, {
      website: payload.website,
      name: payload.name,
      logo: payload.logo,
    }, {headers: {Authorization: rootState.auth.token?.token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getTeams');
        return data;
      } else {
        return data;
      }
    });
  },
  getTeams({dispatch, commit, rootState}): any {
    return Vue.http.get(`teams`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadTeams', data.teams);
        return true;
      } else {
        return false;
      }
    });
  },
  addToTeam({dispatch, rootState}, payload: any): any {
    return Vue.http.post(`teams/${payload.teamID}/${payload.userID}`,
        {},
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getUsers');
        return data;
      } else {
        return data;
      }
    });
  },
  removeFromTeam({dispatch, rootState}, payload: any): any {
    return Vue.http.delete(`teams/${payload.teamID}/${payload.userID}`,
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getUsers');
        return data;
      } else {
        return data;
      }
    });
  },
  getLogs({dispatch, commit, rootState}, payload:
      {page: number, perPage: number, type: string, user: string, before: number, after: number} =
      {page: 1, perPage: 20, type: 'any', user: 'any', before: -1, after: -1}): any {
    let queryParams = `?page=${payload.page}&perPage=${payload.perPage}`;
    if (payload.type !== 'any') {
      queryParams += `&type=${payload.type}`;
    }
    if (payload.user !== 'any') {
      queryParams += `&user=${payload.user}`;
    }
    if (payload.before !== -1) {
      queryParams += `&before=${payload.before}`;
    }
    if (payload.after !== -1) {
      queryParams += `&after=${payload.after}`;
    }
    return Vue.http.get(`logs${queryParams}`,
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        data.items = data.items.map((i: LogItem) => {
          if (i.user === null) {
            const itemData = JSON.parse(i.data);
            if (itemData.user) {
              i.user = itemData.user;
            }
          }
          return i;
        });
        commit('loadLogs', data);
        return true;
      } else {
        return false;
      }
    });
  },
  getFeaturedPacks({dispatch, commit, rootState}): any {
    return Vue.http.get(`featured`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadFeatured', data.packs);
        return true;
      } else {
        return false;
      }
    });
  },
  setFeaturedPack({dispatch, commit, rootState}, payload: {id: number, featured: boolean}): any {
    return Vue.http.post(`featured/${payload.id}`, {featured: payload.featured}, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadFeatured', data.packs);
      }
      return data;
    });
  },
  getSettings({dispatch, commit, rootState}): any {
    commit('loading', true);
    return Vue.http.get(`settings`, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadSettings', data.settings);
      }
      return data
    });
  },
  setSetting({dispatch, commit, rootState}, payload: {id: number, value: string}): any {
    commit('loading', true);
    return Vue.http.patch(`settings/${payload.id}`,
        {value: payload.value},
        {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getSettings');
      }
      return data;
    });
  }
};
