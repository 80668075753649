import store from "@/store";
import router from "@/router";
import {ApiResponse} from "@/core/api/apiResponse";

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "UPDATE" | "DELETE";

export type RequestOptions = {
    headers?: Record<string, string>;
    expectJson?: boolean;
}

export const curatorApiRootUrl = process.env.VUE_APP_API_URL
    ? process.env.VUE_APP_API_URL
    : 'https://api.curator.modpacks.ch'

export type ApiType = typeof Api;

class Api {
    async get<T>(endpoint: string, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("GET", endpoint, undefined, options);
    }

    async post<T>(endpoint: string, body?: object, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("POST", endpoint, body, options);
    }

    async put<T>(endpoint: string, body?: object, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("PUT", endpoint, body, options);
    }

    async patch<T>(endpoint: string, body?: object, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("PATCH", endpoint, body, options);
    }

    async update<T>(endpoint: string, body?: object, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("UPDATE", endpoint, body, options);
    }

    async delete<T>(endpoint: string, options?: RequestOptions): Promise<ApiResponse<T>> {
        return await this.request("DELETE", endpoint, undefined, options);
    }

    /**
     * Not so nice fetch wrapper with integration to the vuex store and well defined error
     * handling.
     *
     * @param method    method type for the http request
     * @param endpoint  location of the endpoint
     * @param body      optional body
     * @param options   optional options to modify the response
     * @private
     */
    public async request<T>(method: HttpMethod, endpoint: string, body?: object | string, options?: RequestOptions): Promise<ApiResponse<T>> {
        try {
            const reqOptions: any = {
                method,
                headers: {
                    ...options?.headers,
                    Authorization: store.state.auth.token?.token ?? "",
                }
            };

            if (!(body instanceof FormData) && (!options?.headers || ( !("content-type" in options?.headers) && !("Content-Type" in options.headers)))) {
                reqOptions.headers["Content-Type"] = "application/json";
            }

            if (body) {
                reqOptions.body = (body instanceof String || body instanceof FormData) ? body : JSON.stringify(body);
            }

            const request = await fetch(curatorApiRootUrl + endpoint, reqOptions);
            if (request.status === 401) {
                this.on401();
                return new ApiResponse<T>(false, "Unauthenticated request")
            }

            if (request.status < 200 || request.status > 299) {
                let error = null;
                if (request.headers.get("content-type")?.includes("application/json")) {
                    const body = await request.json();
                    error = body.error ?? body.message;
                }

                if (request.status === 404) {
                    error = `${endpoint} does not exist`
                }

                return new ApiResponse<T>(false, error ?? `Unknown error from the API with the status of ${request.status}`)
            }

            if (typeof options !== "undefined" && options.expectJson === false) {
                return new ApiResponse<T>(true, undefined, await request.text() as any)
            } else {
                return new ApiResponse<T>(true, undefined, (await request.json()) as T);
            }
        } catch(error: any) {
            // This typically means there has been a network error
            console.error(error);
        }

        return new ApiResponse<T>(false, `Fatal error handling request to ${endpoint}`)
    }

    private on401() {
        store.commit("auth/logout");
        router.push("/login").catch(console.error)
    }
}

export const requester = new Api();
