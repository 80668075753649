import {AuthState} from '@/modules/auth/types';
import {ModpacksState} from '@/modules/modpacks/types';
import {HttpOptions, HttpResponse, $http, $resource} from 'vue-resource/types/vue_resource';
import {CacheState} from '@/modules/cache/types';
import {ModpacksEndpoint, PlayersEndpoint, TagsEndpoint, UsersEndpoint, VersionsEndpoint} from "@core/api/endpoints";
import {GroupsEndpoint} from "@core/api/endpoints/groups";
import {ColorModifiers} from "buefy/types/helpers";

export interface RootState {
  version: string;
  auth: AuthState;
  modpacks: ModpacksState;
  cache: CacheState;
}

export enum UserRole {
  user = 0,
  packdev = 1,
  trustedpackdev = 2,
  approver = 3,
  admin = 4,
}

export const titles: string[] = ['Author', 'Contributor', 'Lead Developer', 'Maintainer', 'Developer', 'Translator', 'Former Author'].sort();

export interface Permission {
  value: string;
  name: string;
  children?: string[];
}

export const packPermissions: readonly Permission[] = Object.freeze([
  {value: 'editPack', name: 'Edit Pack', children: ['basicPackInfo', 'packDescription', 'packPrivate']},
  {value: 'basicPackInfo', name: 'Edit basic pack info'},
  {value: 'packDescription', name: 'Edit pack description'},
  {value: 'packPrivate', name: 'Private / Public a pack'},
  {value: 'editUsers', name: 'Edit Users', children: ['addRemoveUsers', 'editUserPermissions']},
  {value: 'addRemoveUsers', name: 'Add or Remove Users'},
  {value: 'editUserPermissions', name: 'Edit user permissions'},
  {
    value: 'editVersions',
    name: 'Edit Versions',
    children: ['createVersion', 'editFiles', 'editMods', 'editBasicVersionInfo', 'editChangelog', 'releaseVersion'],
  },
  {value: 'createVersion', name: 'Create/Import new versions'},
  {value: 'editFiles', name: 'Edit files'},
  {value: 'editMods', name: 'Edit mods'},
  {value: 'editBasicVersionInfo', name: 'Edit basic version info'},
  {value: 'editChangelog', name: 'Edit changelog'},
  {value: 'releaseVersion', name: 'Release Version'},
]);

export type EndpointsProto = {
  modpacks: ModpacksEndpoint,
  versions: VersionsEndpoint,
  tags: TagsEndpoint,
  users: UsersEndpoint,
  groups: GroupsEndpoint,
  players: PlayersEndpoint
};

declare module 'vue/types/vue' {
  interface Vue {
    $http: {
      (options: HttpOptions): PromiseLike<HttpResponse>;
      get: $http;
      post: $http;
      put: $http;
      patch: $http;
      delete: $http;
      jsonp: $http;
    };
    $resource: $resource;

    hasPermission(userRole: UserRole | string, requiredRole: UserRole): boolean

    displayWarning(message: string): void;
    displaySuccess(message: string): void;
    displayDanger(message: string): void;
    displayInfo(message: string): void;

    confirmPrompt(title: string, message: string, type?: ColorModifiers): Promise<boolean>
    confirmPromptStandard(message: string, type?: ColorModifiers): Promise<boolean>;

    $endpoints: EndpointsProto
  }

  interface VueConstructor {
    http: {
      (options: HttpOptions): PromiseLike<HttpResponse>;
      get: $http;
      post: $http;
      put: $http;
      patch: $http;
      delete: $http;
      jsonp: $http;
    };
    resource: $resource;
  }
}

export const StandardArtTypesList = ["square", "splash", "social-twitter", "social-open-graph"] as const
export type StandardArtTypes = typeof StandardArtTypesList[number];

export type CreateVersionPayload = {
  loader: string,
  loader_version: string,
  name: string,
  type: string,
}

export type CreateModpackPayload = {
  name: string;
  mc_version: string;
  type: string;
  summary: string;
}

export type MinecraftVersion = {
  id: string;
  type: string;
  url: string;
  time: Date;
  releaseTime: Date;
}

export type JavaVersion = {
  build: number
  major: number
  minor: number
  openjdk_version: string
  security: number
  semver: string
  patch?: number
  adopt_build_number?: number
}
