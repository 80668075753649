import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {RootState} from '@/types';
import {AdminState} from '@/modules/admin/types';

export const state: AdminState = {
  loading: false,
  error: false,
  approvalQueue: [],
  users: [],
  teams: [],
  groups: [],
  activeGroup: -1,
  logs: [],
  totalLogs: 0,
  featured: [],
  settings: []
};

export const admin: Module<AdminState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
