import dayjs, {Dayjs} from "dayjs";
import timeAgo from "dayjs/plugin/relativeTime"
import {PluginObject} from "vue/types/plugin";
dayjs.extend(timeAgo)

declare module 'vue/types/vue' {
    interface Vue {
        $dayjs: Dayjs;
    }
}

export const DayjsPlugin: PluginObject<any> = {
    install(Vue) {
        Vue.prototype.$dayjs = dayjs

        Vue.filter("dayjs", (value: any): Dayjs => {
            return dayjs(value);
        })

        Vue.filter("readable", (value: any): string => {
            return dayjs(value).format("MMM DD, YYYY HH:mm A");
        })

        Vue.filter("ago", (value: any) => {
            return dayjs(value).fromNow();
        })
    }
}
