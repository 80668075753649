import {ToastProgrammatic} from "buefy/types/components";
import dayjs from "dayjs";
import {HttpMethod, requester, RequestOptions} from "@core/api";

export type CacheWrappedData<T> = {
  key: string;
  data: T,
  expires: number
}

export interface RequestOptionsWithBody extends RequestOptions {
  body?: object | string;
}

export class Endpoint {
  private readonly base: string;
  private readonly cacheHolder = new Map<string, CacheWrappedData<any>>();


  constructor(base: string = "") {
    this.base = base;
  }

  /**
   * Build an endpoint supporting an empty string (by removing the trailing slash
   * @param endpoint the location of the data
   */
  url(endpoint: string | number) {
    if (this.base === "") {
      return `/${endpoint}`
    }

    return endpoint === "" ? `/${this.base}` : `/${this.base}/${endpoint}`;
  }

  /**
   * Memory based cache system that auto expires after 10 minutes.
   *
   * The cache will only be purged from memory then {@link clearCache} is called
   * or a new {@link getOrCreate} request is made.
   */
  commitToCache<T>(key: string, data: T): CacheWrappedData<T> {
    const cacheData = {
      data,
      key,
      expires: dayjs().add(10, "minute").unix()
    };

    this.cacheHolder.set(key, cacheData);
    return cacheData;
  }

  /**
   * Cached backed data fetching
   *
   * @param key the key of the cache to create or fetch from
   * @param createAction if the cache data does not exist, run this method to create it
   */
  async getOrCreate<T>(key: string, createAction: () => Promise<T>): Promise<CacheWrappedData<T>> {
    const cacheData = this.cacheHolder.get(key);
    if (cacheData && dayjs().unix() < cacheData.expires) {
      return cacheData;
    }

    // Create data & cache it.
    return this.commitToCache(key, await createAction());
  }

  /**
   * Wrapper for the request method that auto falls back onto null and will display any errors
   */
  async request<T>(method: HttpMethod, endpoint: string | number = "", options: RequestOptionsWithBody = {}, cache = true): Promise<T | null> {
    if (!cache) {
      return this._request(method, endpoint, options);
    }

    // TODO: factor in support for source as a salt for the key of the cache
    const cacheData = await this.getOrCreate<T | null>(
        `${this.base}/${endpoint}`,
        () => this._request<T>(method, endpoint, options)
    );

    return cacheData.data;
  }

  /**
   * Very much the same as the {@link request} method above but instead of returning the raw result we return a dynamically
   * transformed version of the data.
   *
   * Why? You might be asking why, this is normal, if you look at the code, you'll see the transformation is made
   * before the cache is created, by doing this we can dramatically reduce the data stored in cache when the api we're
   * dealing with provides way to much data that we don't need.
   *
   * @param method http method
   * @param endpoint the endpoint you want to query
   * @param transformer a method that will take the response and transform it
   * @param options options for the http request
   * @param cache should we cache or not?
   */
  async requestWithTransformer<T, V>(method: HttpMethod, endpoint: string | number = "", transformer: (response: T) => V, options: RequestOptionsWithBody = {}, cache = true): Promise<V | null> {
    if (!cache) {
      const response = await this._request<T>(method, endpoint, options);
      if (response === null) {
        return null;
      }

      return transformer(response);
    }

    const cacheData = await this.getOrCreate<V | null>(
        `${this.base}/${endpoint}/transformed`,
        async () => {
          const result = await this._request<T>(method, endpoint, options);
          if (result === null) {
            return null;
          }

          return transformer(result)
        }
    );

    return cacheData.data;
  }

  private async _request<T>(method: HttpMethod, endpoint: string | number = "", options: RequestOptionsWithBody = {}) {
    const {body, ...rest} = options;
    const data = await requester.request<T>(method, this.url(endpoint), body, rest as RequestOptions);
    if (data.containedError()) {
      return null;
    }

    return data.response ?? null
  }

  removeCache(key: string) {
    this.cacheHolder.delete(key);
  }

  clearCache() {
    this.cacheHolder.clear();
  }
}
