export class Data<T> {
    private readonly _data: T;

    constructor(data: T) {
        this._data = data;
    }

    get data(): T {
        return this._data;
    }
}
