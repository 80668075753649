import {GroupsResponse, ResponseHolder, TagsResponse} from "@core/api";
import {Endpoint} from "@core/api/endpoints";
import {ToastProgrammatic} from "buefy/types/components";

export class GroupsEndpoint extends Endpoint {
    constructor() {
        super("groups");
    }

    //POST /groups/:id/players
    //DELETE - /groups/:id/players/:pid
    public async getGroups(force: boolean = false) {
        return await this.request<GroupsResponse>("GET", '', {}, !force);
    }

    public async addPlayerToGroup(groupId: number, playerId: number) {
        return await this.request<ResponseHolder>("POST", `${groupId}/players`, {
            body: {
                playerId
            }
        }, false)
    }

    public async removePlayerFromGroup(groupId: number, playerId: number) {
        return this.request<ResponseHolder>("DELETE", `${groupId}/players/${playerId}`, {}, false)
    }
}
