import {GetterTree} from 'vuex';
import {AuthState} from './types';
import {RootState} from '@/types';

export const getters: GetterTree<AuthState, RootState> = {

  isLoggedIn: (state: AuthState) => {
    return !!state.token;
  },

  isAdmin: (state: AuthState) => {
    return !!state.token && state.user && state.user.role === 'admin';
  },

};
