import {TagsResponse} from "@core/api";
import {Endpoint} from "@core/api/endpoints";
import {ToastProgrammatic} from "buefy/types/components";

export class TagsEndpoint extends Endpoint {
    constructor() {
        super("tags");
    }

    public async getTags() {
        return (await this.request<TagsResponse>("GET"))?.tags ?? null;
    }
}
