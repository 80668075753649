import {MutationTree} from 'vuex';
import {AdminState, ApprovalQueueItem, LogItem, Team, Group} from './types'
import {User} from '@/modules/auth/types';

export const mutations: MutationTree<AdminState> = {
  loadQueue(state, payload: ApprovalQueueItem[]) {
    state.approvalQueue = payload;
    state.loading = false;
  },
  loading(state) {
    state.loading = true;
  },
  loadUsers(state, payload: User[]) {
    state.users = payload;
    state.loading = false;
  },
  loadGroups(state, payload: Group[]) {
    state.groups = payload;
    state.loading = false;
  },
  setActiveGroup(state, payload) {
    state.activeGroup = payload;
  },
  loadTeams(state, payload: Team[]) {
    state.teams = payload;
    state.loading = false;
  },
  loadLogs(state, payload: {items: LogItem[], total: number}) {
    state.logs = payload.items;
    state.totalLogs = payload.total;
    state.loading = false;
  },
  loadFeatured(state, payload: any[]) {
    state.featured = payload;
    state.loading = false;
  },
  loadSettings(state, payload: any[]) {
    state.settings = payload;
    state.loading = false;
  }
};
