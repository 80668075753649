
import {Component, Vue} from 'vue-property-decorator';
import Sidebar from './Sidebar.vue';
import Header from './Header.vue';

@Component({
  components: {Sidebar, Header},
})
export default class Layout extends Vue {
}
