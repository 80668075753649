
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {CreateModpackPayload, MinecraftVersion} from "@/types";

@Component({
  props: ['create'],
})
export default class NewVersionModal extends Vue {
  @Prop() public create: any;

  name = '';
  summary = '';
  mcVersion = '';
  // type = null;

  loadingMinecraftVersions = false;
  minecraftVersions: MinecraftVersion[] = [];

  async mounted() {
    this.loadingMinecraftVersions = true;
    const result = await this.$endpoints.versions.getMinecraftVersions();
    if (result?.success) {
      this.minecraftVersions = result.versions;
      this.mcVersion = result.versions[0].id
    }
    this.loadingMinecraftVersions = false;
  }

  submit() {
    if (this.name === "" || this.summary === "" || this.mcVersion === "") {
      this.displayWarning("Please fill out all of the form")
      return;
    }

    this.$emit("create", {
      name: this.name,
      mc_version: this.mcVersion,
      type: "Release", // TODO: ask jake to remove this
      summary: this.summary,
    } as CreateModpackPayload);
  }
}
