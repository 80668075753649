import { ToastProgrammatic as Toast } from 'buefy'

/**
 * Wraps an Api request to clean up its response, provide a clear success / failure var and
 * push a message to display to the user. Along with this, we also provide helper methods to
 * show errors.
 */
export class ApiResponse<T> {
    ok: boolean;
    message?: string;
    response?: T;

    constructor(ok: boolean, message?: string, response?: T) {
        this.ok = ok;
        this.message = message;
        this.response = response;
    }

    /**
     * Did I error?
     */
    isError() {
        return !this.ok;
    }

    /**
     * Handles displaying the error from the api request. If there's no error, we return false,
     * if there is an error, we'll bring up a toast to display it.
     */
    containedError() {
        if (this.isError()) {
            Toast.open({
                message: this.message ?? "Unknown error occurred with the API",
                type: 'is-danger',
                duration: 2000,
            })

            return true;
        }

        return false;
    }
}
