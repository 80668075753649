import {
    SingleModpacksResponse,
    ModpackTokensResponse,
    ResponseHolder,
    ModpackArtResponse,
    ModpackArtSingleResponse,
    AddUserToPackResponse,
    ModpacksResponse,
    ModpackVersionResponse,
    ModpackVersionFilesResponse,
    ModpackVersionFilesFixedResponse,
    CreateModpackTokensResponse,
    ModpackCreateLinkResponse
} from "@core/api";
import {Endpoint} from "@core/api/endpoints";
import {ModpackData} from "@core/api/data";
import {Token} from "@/modules/auth/types";
import {CreateModpackPayload, CreateVersionPayload} from "@/types";
import {ModPack, ModPackFile, ModPackUser, ModpackVersion2} from "@/modules/modpacks/types";

type UpdateableModpack = Partial<Pick<
    ModPack,
    "name" | "description" | "summary" | "webhook" | "notification" |
    "mc_version" | "activeLogo" | "activeCover" | "issueTracker"
> & {tags: number[]}>

export class ModpacksEndpoint extends Endpoint {
    constructor() {
        super("modpacks");
    }

    // We don't cache this endpoint, ever, fuck that, errors man!
    public async getModpacks() {
        return this.request<ModpacksResponse>("GET", "", {}, false);
    }

    /**
     * WARNING, This method does not return a full modpack for some reason
     * @param payload
     */
    public async createModpack(payload: CreateModpackPayload) {
        return this.request<SingleModpacksResponse>("POST", "", {
            body: payload
        }, false);
    }

    public async getModpack(id: number, cache = true) {
        const data = await this.request<SingleModpacksResponse>("GET", id, {}, cache);

        return data ? new ModpackData(data.modpack) : null;
    }

    public async getArt(id: number, force = false) {
        return await this.request<ModpackArtResponse>("GET", `${id}/art`, {}, !force);
    }

    public async uploadArtwork(id: number, formData: FormData) {
        return await this.request<ModpackArtSingleResponse>("POST", `${id}/art`, {
            body: formData,
        }, false);
    }

    public async deleteArtwork(id: number, artId: number) {
        return await this.request<ResponseHolder>("DELETE", `${id}/art/${artId}`, {}, false)
    }

    //#region scoped update methods
    public async updateModpack(id: number, packOptions: UpdateableModpack) {
        return await this.request<SingleModpacksResponse>("PUT", `${id}`, {
            body: packOptions
        }, false)
    }

    public async changeVisibility(id: number, visible: boolean) {
        return await this.request<SingleModpacksResponse>("PUT", `${id}`, {
            body: {
                private: !visible
            }
        }, false)
    }
    //#endregion

    public async addUserToPack(packId: number, userId: number, role: string) {
        return await this.request<AddUserToPackResponse>("POST", `${packId}/users`, {
            body: {
                uid: userId,
                role
            }
        }, false)
    }

    public async modifyPackUserPermissions(packId: number, userId: number, permissions: string[]) {
        return await this.request<any>("PATCH", `${packId}/users/${userId}`, {
            body: permissions
        }, false)
    }

    public async removeUserFromPack(packId: number, userId: number) {
        return await this.request<ResponseHolder>("DELETE", `${packId}/users/${userId}`, {}, false);
    }

    public async addGroupToPack(packId: number, groupId: number) {
        return await this.request<ResponseHolder>("POST", `${packId}/groups`, {
            body: {groupId},
        }, false)
    }

    public async removeGroupFromPack(packId: number, groupId: number) {
        return await this.request<ResponseHolder>("DELETE", `${packId}/groups`, {
            body: {
                groupId
            }
        }, false);
    }

    public async addPlayerToPack(packId: number, playerId: number) {
        return await this.request<ResponseHolder>("POST", `${packId}/players`, {
            body: { playerId: playerId }
        }, false);
    }

    public async removePlayerFromPack(packId: number, playerId: number) {
        return await this.request<ResponseHolder>("DELETE", `${packId}/players/${playerId}`, {}, false);
    }

    //#region Modpack version endpoints

    public async createPackVersion(packId: number, meta: CreateVersionPayload) {
        return await this.request<ResponseHolder>("POST", `${packId}`, {
            body: meta
        }, false);
    }

    public async getModPackVersion(packId: number, versionId: number, force = false) {
        return await this.request<ModpackVersionResponse>("GET", `${packId}/versions/${versionId}`, {}, !force);
    }

    public async getModpackVersionFiles(packId: number, versionId: number, force = false) {
        return await this.request<ModpackVersionFilesResponse>("GET", `${packId}/versions/${versionId}/files`, {}, !force);
    }

    public async updateModpackVersion(packId: number, versionId: number, data: ModpackVersion2) {
        return await this.request<ResponseHolder>("PATCH", `${packId}/versions/${versionId}`, {
            body: data,
        }, false)
    }

    public async uploadFilesToVersion(packId: number, versionId: number, formData: FormData) {
        return await this.request<ResponseHolder>("POST", `${packId}/versions/${versionId}/upload`, {
            body: formData,
        }, false)
    }

    public async updateModpackVersionFile(packId: number, fileId: number, file: ModPackFile) {
        return this.request<ResponseHolder>("PATCH", `${packId}/files/${fileId}`, {
            body: file
        }, false)
    }

    public async releaseModpackVersion(packId: number, versionId: number) {
        return this.request<ResponseHolder>("POST", `${packId}/versions/${versionId}/release`, {}, false)
    }

    public async deleteModpackVersionFile(packId: number, fileId: number) {
        return this.request<ResponseHolder>("DELETE", `${packId}/files/${fileId}`, {}, false);
    }

    public async deleteModpackVersion(packId: number, versionId: number) {
        return this.request<ResponseHolder>("DELETE", `${packId}/versions/${versionId}`, {}, false)
    }

    //#endregion

    //#region links

    public async createModpackLink(packId: number, name: string, type: string, link: string) {
        return this.request<ModpackCreateLinkResponse>("POST", `${packId}/link`, {
            body: {
                url: link,
                name,
                type
            }
        }, false)
    }

    public async updateModpackLink(packId: number, linkId: number, name: string, type: string, link: string) {
        return this.request<ModpackCreateLinkResponse>("PATCH", `${packId}/link/${linkId}`, {
            body: {
                url: link,
                name,
                type
            }
        }, false)
    }

    public async deleteModpackLink(packId: number, linkId: number) {
        return this.request<ResponseHolder>("DELETE", `${packId}/link/${linkId}`, {}, false)
    }

    //#endregion

    /**
     * Gets the tokens for a modpack id
     *
     * @param id the id of the modpack
     */
    public async getTokens(id: number, force = false) {
        return await this.request<ModpackTokensResponse>("GET", `${id}/token`, {}, !force);
    }

    public async deleteToken(packId: number, tokenId: number) {
        return this.request<ResponseHolder>("DELETE", `${packId}/token/${tokenId}`, {}, false)
    }

    public async createToken(id: number) {
        return await this.request<CreateModpackTokensResponse>("POST", `${id}/token`, undefined, false)
    }

    public async updateAuthor(id: number, user: { id: number; name: string; type: string }) {
        return await this.request<any>("POST", `${id}/author`, {
            body: user
        }, false)
    }

    public async deleteModpack(packId: number) {
        return this.request<ResponseHolder>("DELETE", packId, {}, false);
    }
}
