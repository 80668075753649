import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Buefy from 'buefy';
import vueResource from 'vue-resource';
import './_variables.scss';
import store from './store';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor, Viewer } from '@toast-ui/vue-editor';
import {EndpointsProto, UserRole} from '@/types';
import {ModPack, ModPackAuthor} from '@/modules/modpacks/types';
import {DayjsPlugin} from "@/plugins/dayjs";
import {curatorApiRootUrl} from "@/core/api";
import {ModpacksEndpoint, PlayersEndpoint, GroupsEndpoint, VersionsEndpoint, UsersEndpoint} from "@core/api/endpoints";
import {TagsEndpoint} from "@core/api/endpoints/tags";
import {SnackbarProgrammatic} from "buefy/types/components";
import {ColorModifiers} from "buefy/types/helpers";
import Copy from "@/components/ui/Copy.vue";

Vue.config.productionTip = false;

Vue.use(DayjsPlugin);

Vue.use(vueResource);
Vue.use(Buefy, {
  defaultIconPack: 'fas',
});

const displaySnackStandard = (bar: typeof SnackbarProgrammatic, type: ColorModifiers, message: string) => {
  bar.open({
    type,
    message,
    queue: true,
    duration: 3500,
    position: "is-bottom-right",
  })
}

async function confirmPrompt(buefy: any, title: string = "Are you sure?", message: string = "This action can not be undone. Ensure this is what you want to do.", type: ColorModifiers = "is-danger"): Promise<boolean> {
  return await new Promise((resolve) => {
    buefy.dialog.confirm({
      title: title,
      message: message,
      type: type,
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
    })
  })
}

Vue.mixin({
  methods: {
    async confirmPrompt(title: string = "Are you sure?", message: string = "This action can not be undone. Ensure this is what you want to do.", type: ColorModifiers = "is-danger"): Promise<boolean> {
      return confirmPrompt(this.$buefy, title, message, type)
    },
    async confirmPromptStandard(message: string, type: ColorModifiers = "is-danger") {
      return confirmPrompt(this.$buefy,"Are you sure?", message, type)
    },
    hasPermission(userRole: UserRole | string, requiredRole: UserRole) {
      if (typeof userRole === 'string') {
        userRole = UserRole[userRole as keyof typeof UserRole];
      }
      return userRole >= requiredRole;
    },
    hasModpackPermission(permission: string | string[], modpack: ModPack) {
      // @ts-ignore
      if (this.hasPermission(this.$store.state.auth.user.role, UserRole.admin)) {
        return true;
      }
      const userID = this.$store.state.auth.user.id;
      const modpackUser: ModPackAuthor = modpack.users.filter((f: ModPackAuthor) => f.user.id === userID)[0];
      if (typeof permission === 'string') {
        return modpackUser.modpack_user_permissions.findIndex((e: any) => e.permission === permission) !== -1;
      } else {
        const userPermissions = modpackUser.modpack_user_permissions.map((e: any) => e.permission);
        return userPermissions.findIndex(e => permission.includes(e)) !== -1;
      }
    },
    displayWarning(message: string) {
      displaySnackStandard(this.$buefy.snackbar, "is-warning", message);
    },
    displaySuccess(message: string) {
      displaySnackStandard(this.$buefy.snackbar, "is-success", message);
    },
    displayDanger(message: string) {
      displaySnackStandard(this.$buefy.snackbar, "is-danger", message);
    },
    displayInfo(message: string) {
      displaySnackStandard(this.$buefy.snackbar, "is-info", message);
    }
  },
});

Vue.component('editor', Editor);
Vue.component('viewer', Viewer);

Vue.component('copy', Copy)

Vue.filter('kb', (val: number) => {
  return Math.floor(val / 1024);
});

Vue.filter('titleCase', (value: string) => {
  return value.split(" ").map(e => e[0].toUpperCase() + e.slice(1)).join(" ")
})

Vue.filter('firstTitleCase', (value: string) => {
  const parts = value.split(" ");
  return parts[0][0].toUpperCase() + parts[0].slice(1) + (parts.length > 1 ? " " : "") + parts.slice(1).join(" ")
})

// @ts-ignore
Vue.http.options.root = curatorApiRootUrl;

// @ts-ignore
Vue.http.interceptors.push((request) => {
  // @ts-ignore
  return (response) => {
    if (response.status === 401 && response.body.debug !== 'cannot access') {
      router.push('/logout');
    }
  };
});

// TODO: Stop this, it sucks, bad mikey brain
Vue.prototype.$endpoints = {
  modpacks: new ModpacksEndpoint(),
  tags: new TagsEndpoint(),
  users: new UsersEndpoint(),
  versions: new VersionsEndpoint(),
  groups: new GroupsEndpoint(),
  players: new PlayersEndpoint()
} as EndpointsProto

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

