import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {CacheState} from './types';
import {RootState} from '@/types';

export const state: CacheState = {
  versions: [],
  forge_versions: {},
  fabric_versions: {},
  tags: [],
  permissions: [],
  players: {},
  java_versions: []
};

export const cache: Module<CacheState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
