import {MutationTree} from 'vuex';
import {CacheState} from './types';
import Vue from 'vue';
export const mutations: MutationTree<CacheState> = {
  storeVersions: (state, payload) => {
    state.versions = payload;
  },
  storeForgeVersion: (state, payload: any) => {
    Vue.set(state.forge_versions, payload.version, payload.versions);
  },
  storeFabricVersion: (state, payload: any) => {
    Vue.set(state.fabric_versions, payload.version, payload.versions);
  },
  storeJavaVersion: (state, payload: any) => {
    state.java_versions = payload;
  },
  storeTags: (state, payload: any) => {
    state.tags = payload;
  },
  storePermissions: (state, payload: any) => {
    state.permissions = payload;
  },
  addPlayer: (state, payload: any) => {
    Vue.set(state.players, payload.hash, payload.display);
  },
};
