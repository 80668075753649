
import {Component, Vue} from 'vue-property-decorator';
import Layout from '@/components/Layout.vue';
import {RootState} from "@/types";

@Component({
  components: {
    Layout,
  },
  beforeMount() {
    // Ensure the route is ready for auth details.
    const token = (this.$store.state as RootState).auth.token?.token;

    if (token) {
      (Vue.http as any).headers.common['Authorization'] = token;
    }

    // @ts-ignore
    this.$http.interceptors.push((request: Request) => {
      return function(response: Response) {
        if (response.status === 401) {
          // @ts-ignore
          this.$store.dispatch('auth/logout');
        }
      };
    });
  }
})
export default class DashboardRouter extends Vue {}
