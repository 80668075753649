import {Endpoint} from "@core/api/endpoints/endpoint";
import {ModpackPlayers, ModpackPlayersSearchResponse, ResponseHolder} from "@core/api";

export class PlayersEndpoint extends Endpoint {
  constructor() {
    super("players");
  }

  async getPlayers(force: boolean = false) {
    return this.request<ModpackPlayers>("GET", ``, {}, !force)
  }

  async search(query: string) {
    return this.request<ModpackPlayersSearchResponse>("GET", `search?search=${query}`, {}, false)
  }

  async createPlayer(nickname: string, friendCode: string) {
    return this.request<ResponseHolder>("POST", ``, {
      body: {
        nickName: nickname,
        friendCode
      }
    }, false)
  }
}
