
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import NewModpackModal from '@/components/modals/NewModpackModal.vue';
import {AuthState} from '@/modules/auth/types';
import {CreateModpackPayload, UserRole} from '@/types';
import {AdminState} from '@/modules/admin/types'
import {sessionCacheKey} from "@/store";

@Component({
  components: {
    NewModpackModal,
  },
})
export default class Sidebar extends Vue {
  @State('auth') public auth!: AuthState;
  @Action('newModPack', {namespace: 'modpacks'}) public newModPack: any;
  @State('admin') public admin!: AdminState;
  @Action('getApprovalQueue', {namespace: 'admin'}) public getApprovalQueue: any;
  @Action('getMe', {namespace: 'auth'}) private getMe!: (token: string | undefined) => void;

  @Action("logout", {namespace: 'auth'}) private logout!: () => void;

  showNew: boolean = false;
  isDev = process.env.NODE_ENV !== "production";

  UserRole = UserRole;

  public mounted() {
    this.getMe(this.auth.token?.token);

    if (this.auth.user) {
      if (this.hasPermission(this.auth.user.role, UserRole.admin)) {
        this.getApprovalQueue();
      }
    }
  }

  public open() {
    this.showNew = true;
  }

  public close() {
    this.showNew = false;
  }

  async create(data: CreateModpackPayload) {
    const result = await this.$endpoints.modpacks.createModpack(data)
    if (result?.success) {
      await this.$router.push(`modpack/${result.modpack.id}`)
      this.showNew = false;
    }
  }

  @Watch('$route', {deep: true})
  public routeChange() {
    this.$forceUpdate();
  }

  shiftFocus() {
    (document as any).activeElement.blur();
  }

  yeetSessionCache() {
    window.sessionStorage.removeItem(sessionCacheKey);
    window.location.reload();
  }

  get isUserAdmin() {
    return this.auth.user?.role ? this.hasPermission(this.auth.user?.role, UserRole.admin) : false;
  }

  get approvalQueueCount(){
    const approvalQueueItems = this.admin && this.admin.approvalQueue;
    return approvalQueueItems.filter((a) => a.type !== 'permission').length || 0;
  }

  async logoutAction() {
    await this.logout();
    window.location.href = "/"
  }
}
