import {ActionTree} from 'vuex';
import {AuthState} from './types';
import Vue from 'vue';
import {RootState} from '@/types';

export const actions: ActionTree<AuthState, RootState> = {
  logout({commit}): void {
    commit('logout');
  },
  login({dispatch, commit}, {code, sessionState}): any {
    commit('authLoggingIn');
    return Vue.http.post(`auth`, {code, session_state: sessionState}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('authLogin', data.token);
        dispatch('getMe', data.token.token);
        return true;
      } else {
        commit('authError', data.error);
        return false;
      }
    });
  },
  getMe({dispatch, commit}, token: string): any {
    return Vue.http.get(`me`, {headers: {Authorization: token}}).then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        commit('loadUser', data.user);
        commit('loadNotifications', data.notifications);
        return true;
      } else {
        dispatch('logout');
        commit('authError', data.error);
        return false;
      }
    });
  },
  markAsRead({dispatch, commit, state}, id: number): any {
    return Vue.http.post(`notification/${id}`, {id}, {headers: {Authorization: state.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getMe', state.token?.token);
        return data;
      } else {
        return data;
      }
    }, (data) => {
      return data;
    });
  },
};
