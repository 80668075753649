import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {ModPack, ModpacksState, ModPackUserPermission, ModPackVersion} from './types';
import Vue from 'vue';
import {RootState} from '@/types';

//region Request
// async function get(rootState: RootState, url: string) {
//   try {
//     const response = await Vue.http.get(url, {headers: {Authorization: rootState.auth.token?.token}})
//     .then((res: any) => res.json())
//     return response;
//   } catch (err) {
//     if (err.bodyText.length > 0) {
//       return err.body;
//     }  else {
//       return {success: false, error: 'Could not connect to API'}
//     }
//   }
// }
//
// async function post(rootState: RootState, url: string, data: any) {
//   try {
//     const response = await Vue.http.post(url, data, {headers: {Authorization: rootState.auth.token?.token}})
//     .then((res: any) => res.json())
//     return response;
//   } catch (err) {
//     if (err.bodyText.length > 0) {
//       return err.body;
//     }  else {
//       return {success: false, error: 'Could not connect to API'}
//     }
//   }
// }
//
// async function put(rootState: RootState, url: string, data: any) {
//   try {
//     const response = await Vue.http.put(url, data, {headers: {Authorization: rootState.auth.token?.token}})
//     .then((res: any) => res.json())
//     return response;
//   } catch (err) {
//     if (err.bodyText.length > 0) {
//       return err.body;
//     }  else {
//       return {success: false, error: 'Could not connect to API'}
//     }
//   }
// }
//
// async function patch(rootState: RootState, url: string, data: any) {
//   try {
//     const response = await Vue.http.patch(url, data, {headers: {Authorization: rootState.auth.token?.token}})
//     .then((res: any) => res.json())
//     return response;
//   } catch (err) {
//     if (err.bodyText.length > 0) {
//       return err.body;
//     }  else {
//       return {success: false, error: 'Could not connect to API'}
//     }
//   }
// }
// async function deleteR(rootState: RootState, url: string) {
//   try {
//     const response = await Vue.http.delete(url, {headers: {Authorization: rootState.auth.token?.token}})
//     .then((res: any) => res.json())
//     return response;
//   } catch (err) {
//     if (err.bodyText.length > 0) {
//       return err.body;
//     }  else {
//       return {success: false, error: 'Could not connect to API'}
//     }
//   }
// }
//endregion

//region Getters
export const getters: GetterTree<ModpacksState, RootState> = {
  activeModpack: (state) => {
    if (state.active) {
      return state.modpacks.get(state.active);
    }
  },
  loading: (state) => {
    return state.loading;
  }
};
//endregion

//region Mutations
export const mutations: MutationTree<ModpacksState> = {
  modpacksLoading(state, b: boolean) {
    state.loading = b;
  },
  loadModpacks(state, modpacks: ModPack[]) {
    modpacks = modpacks.map((modpack: ModPack) => {
      modpack.users = modpack.users.map((user) => {
        // @ts-ignore
        user.modpack_user_permissions = user.modpack_user_permissions.map((p: ModPackUserPermission) => {
          return p.permission;
        });
        return user;
      });
      return modpack;
    });

    state.modpacks = new Map<number, ModPack>(modpacks.map(modpack => [modpack.id, modpack]));
  },
  loadModpack(state, modpack: ModPack) {
    modpack.users = modpack.users.map((user) => {
      // @ts-ignore
      user.modpack_user_permissions = user.modpack_user_permissions.map((p: ModPackUserPermission) => {
        return p.permission;
      });
      return user;
    });
    state.modpacks.set(modpack.id as number, modpack);
  },
  loadVersion(state, version: ModPackVersion) {
    // Versions can be undefined sometimes
    if (version) {
      version.mods = [];
    }

    state.version = version;
  },
  searchResults(state, results: any) {
    state.results = results;
    state.searching = false;
  },
  startSearch(state) {
    state.searching = true;
  },
  endSearch(state) {
    state.searching = false;
  },
  loadMods(state, payload) {
    if (state.version) {
      state.version.mods = payload;
    }
  },
  setActiveModPack(state, payload) {
    state.active = payload;
  },
  clearResults(state) {
    state.results = [];
  },
  importStatus(state, payload) {
    Vue.set(state.imports, payload.id, payload.status);
  },
  deleteImport(state, payload) {
    delete state.imports[payload];
  },

};
//endregion

export const actions: ActionTree<ModpacksState, RootState> = {
  async newVersion({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use ")
    // const response = await post(rootState, `modpacks/${payload.id}`, payload.version);
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async getModpackVersion({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await get(rootState, `modpacks/${payload.modpack}/versions/${payload.version}`);
    // if (response.success) {
    //   commit('loadVersion', response.version);
    // }
    // return response
  },
  async getVersionMods({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await get(rootState, `modpacks/${payload.modpack}/versions/${payload.version}/mods`);
    // if (response.success) {
    //   commit('loadMods', response.files);
    // }
    // return response;
  },
  async addFileToVersion({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await post(rootState, `modpacks/${payload.modpack}/versions/${payload.version}/upload`, payload.form);
    // if (response.success) {
    //   dispatch('getVersionMods', {modpack: payload.modpack, version: payload.version});
    // }
    // return response;
  },
  async newModPack({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await post(rootState, `modpacks/`, payload);
    // if (response.success) {
    //   dispatch('getModpacks');
    // }
    // return response;
  },
  async importVersion({dispatch, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // let uploadURL = process.env.NODE_ENV === 'production' ? `https://upload.curator.modpacks.ch/modpacks/${payload.id}/versions/import` : `modpacks/${payload.id}/versions/import`
    // try {
    //   const response = await Vue.http.post(uploadURL, payload.data, {headers: {Authorization: rootState.auth.token?.token}})
    //   .then((res: any) => res.json())
    //   return response;
    // } catch (err) {
    //   if (err.bodyText.length > 0) {
    //     return err.body;
    //   }  else {
    //     return {success: false, error: 'Could not connect to API'}
    //   }
    // }
  },
  async deleteVersion({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await deleteR(rootState, `modpacks/${payload.id}/versions/${payload.vid}`);
    // if (response.success) {
    //     dispatch('getModpacks');
    // }
    // return response;
  },
  async searchUsers({dispatch, commit, rootState}, payload: string): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await get(rootState, `users/search?search=${payload}`);
    // if (response.success) {
    //   commit('searchResults', response.users);
    // } else {
    //   commit('endSearch');
    // }
    // return response;
  },
  async addUser({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await post(rootState, `modpacks/${payload.id}/users`, {role: payload.role, uid: payload.uid});
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async deleteUser({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await deleteR(rootState, `modpacks/${payload.id}/users/${payload.uid}`);
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async updateUser({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await patch(rootState, `modpacks/${payload.id}/users/${payload.uid}`, payload.permissions);
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async addPlayer({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await post(rootState, `modpacks/${payload.id}/players`, {friendCode: payload.friendCode});
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async deletePlayer({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // return Vue.http.delete(`modpacks/${payload.id}/players/${payload.uid}`,
    //     {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     dispatch('getModpack', payload.id);
    //   }
    //   return data;
    // });
  },
  async addGroup({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // const response = await post(rootState, `modpacks/${payload.id}/groups`, {groupId: payload.groupId});
    // if (response.success) {
    //   dispatch('getModpack', payload.id);
    // }
    // return response;
  },
  async deleteGroup({dispatch, commit, rootState}, payload: any): Promise<any> {
    throw new Error("Attempted to use async from the VueX Store")
    // return Vue.http.delete(`modpacks/${payload.id}/groups/${payload.id}`,
    //   {headers: {Authorization: rootState.auth.token?.token}})
    //   .then((res: any) => res.json()).then((data: any) => {
    //     if (data.success) {
    //       dispatch('getModpack', payload.id);
    //     }
    //     return data;
    //   });
  },
  releaseVersion({dispatch, rootState}, payload: any) {
    throw new Error("Attempted to use releaseVersion from the VueX Store")
    // return Vue.http.post(`modpacks/${payload.id}/versions/${payload.vid}/release`,
    //     {}, {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     dispatch('getModpack', payload.id);
    //   }
    //   return data;
    // });
  },
  pushToLauncher({dispatch, rootState}, payload: any) {
    throw new Error("Attempted to use pushToLauncher from the VueX Store")
    // return Vue.http.post(`modpacks/${payload}/push`,
    //     {},
    //     {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json());
  },
  updateVersion({dispatch, commit, rootState}, payload: any): any {
    throw new Error("Attempted to use updateVersion from the VueX Store")
    // return Vue.http.patch(`modpacks/${payload.id}/versions/${payload.vid}`,
    //     payload.version, {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     dispatch('getModpack', payload.id);
    //   }
    //   return data;
    // });
  },
  updateFile({dispatch, commit, rootState}, payload: any): any {
    throw new Error("Attempted to use updateFile from the VueX Store")
    // return Vue.http.patch(`modpacks/${payload.id}/files/${payload.fid}`,
    //     payload.file, {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     dispatch('getVersionMods', {modpack: payload.id, version: payload.vid});
    //   }
    //   return data
    // });
  },
  deleteFile({dispatch, commit, rootState}, payload: any): any {
    throw new Error("Attempted to use deleteFile from the VueX Store")
    // return Vue.http.delete(`modpacks/${payload.id}/files/${payload.fid}`,
    //     {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     dispatch('getVersionMods', {modpack: payload.id, version: payload.vid});
    //   }
    //   return data
    // });
  },
  getImportStatus({dispatch, commit, rootState}, id: any): any {
    throw new Error("Attempted to use getImportStatus from the VueX Store")
    // return Vue.http.get(`import/${id}`, {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json()).then((data: any) => {
    //   if (data.success) {
    //     commit('importStatus', {id, status: data.status});
    //   }
    //   return data
    // });
  },
  deleteImportStatus({dispatch, commit, rootState}, id: any): any {
    throw new Error("Attempted to use deleteImportStatus from the VueX Store")
    // commit('deleteImport', id);
  },
  checkVersionPermissions({dispatch, commit, rootState}, payload: any): any {
    throw new Error("Attempted to use checkVersionPermissions from the VueX Store")
    // return Vue.http.get(`modpacks/${payload.id}/versions/${payload.vid}/permissions`,
    //     {headers: {Authorization: rootState.auth.token?.token}})
    // .then((res: any) => res.json())
  },
};
