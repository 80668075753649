import {MutationTree} from 'vuex';
import {AuthState, Token, User} from './types';

export const mutations: MutationTree<AuthState> = {
  authLogin(state, payload: Token) {
    state.loading = false;
    state.token = payload;
  },
  authLoggingIn(state) {
    state.loading = true;
  },
  authError(state) {
    state.loading = false;
    state.error = true;
  },
  logout(state) {
    state.loading = false;
    state.token = undefined;
    state.error = false;
  },
  loadUser(state, payload: User) {
    state.user = payload;
  },
  loadNotifications(state, payload) {
    state.notifications = payload;
  },
};
