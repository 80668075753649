import {ModPack, ModPackArt} from "@/modules/modpacks/types";
import {Data} from "@core/api/data/data";

import PlaceHolderArtwork from '@/assets/placeholder_art.png'

export class ModpackData extends Data<ModPack> {
    static DEFAULT_ART_LOGO: ModPackArt = {
        width: 1024, height: 1024, id: -1, type: "square", url: PlaceHolderArtwork
    }

    public getLogo(): ModPackArt {
        return this.data.modpack_arts.find(e => e.type === "square") ?? ModpackData.DEFAULT_ART_LOGO;
    }
}
