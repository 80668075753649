import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {AuthState} from './types';
import {RootState} from '@/types';

export const state: AuthState = {
  token: undefined,
  loading: false,
  error: false,
  user: undefined,
  notifications: [],
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
