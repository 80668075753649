import {Module} from 'vuex';
import {ModpacksState} from './types';
import {RootState} from '@/types';
import {actions, getters, mutations} from "@/modules/modpacks/actions";

export const state: ModpacksState = {
  loading: false,
  error: false,
  modpacks: new Map,
  version: null,
  results: [],
  searching: false,
  active: null,
  imports: {},
};

export const modpacks: Module<ModpacksState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
