
import {Component, Prop, Vue} from 'vue-property-decorator';
import clipboard from "clipboardy";

type TooltipPos = 'is-top' | 'is-bottom' | 'is-left' | 'is-right'

@Component
export default class Copy extends Vue {
  @Prop() text!: string;
  @Prop({default: 'is-right'}) position!: TooltipPos;

  copied = false;
  copyTimeout: any = null;

  async copy() {
    await clipboard.write(this.text);

    if (!this.copyTimeout) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Copied to clipboard',
        type: 'is-success',
      })
    }

    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }

    this.copied = true;
    this.copyTimeout = setTimeout(() => {
      this.copied = false;
      this.copyTimeout = null;
    }, 1000);
  }
}
