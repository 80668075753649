import {GetterTree} from 'vuex';
import {RootState} from '@/types';
import {AdminState} from '@/modules/admin/types';

export const getters: GetterTree<AdminState, RootState> = {
  getActiveGroup: (state) => {
    if (state.activeGroup) {
      return state.groups.find(g => g.id === state.activeGroup);
    }
  },
};
