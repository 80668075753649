
import {Component, Prop, Vue} from 'vue-property-decorator';
import {AuthState} from '../modules/auth/types';

@Component
export default class Header extends Vue {
  @Prop({})
  public auth!: AuthState;

}
