import {ActionTree} from 'vuex';
import {RootState} from '@/types';
import {CacheState, Permission} from '@/modules/cache/types';
import Vue from 'vue';

export const actions: ActionTree<CacheState, RootState> = {
  async getTags({rootState, commit}): Promise<boolean> {
    const request = await Vue.http.get(`tags`, {headers: {Authorization: rootState.auth.token?.token}});
    const data = await request.json();

    if (data.success) {
      commit('storeTags', data.tags);
    }

    return data.success ?? false;
  },
  async getPermissions({rootState, commit}) {
    const request = await Vue.http.get(`permissions`, {headers: {Authorization: rootState.auth.token?.token}});
    const data = await request.json();

    if (data.success) {
      commit('storePermissions', data.permissions);
    }

    return data.success ?? false;
  },
  addNewPermission({rootState, commit, dispatch}, payload: any) {
    return Vue.http.post(`permissions`, payload, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getPermissions');
      }
      return data;
    }, (err) => {
      return err;
    });
  },
  savePermission({rootState, commit, dispatch}, payload: Permission) {
    return Vue.http.patch(`permissions/${payload.id}`, payload, {headers: {Authorization: rootState.auth.token?.token}})
    .then((res: any) => res.json()).then((data: any) => {
      if (data.success) {
        dispatch('getPermissions');
      }
      return data;
    }, (err) => {
      return err;
    });
  },
  async deletePermission({rootState, commit, dispatch}, payload: Number): Promise<{ ok: boolean, raw?: any }> {
    const request = await Vue.http.delete(`permissions/${payload}`, {headers: {Authorization: rootState.auth.token?.token}})
    const data = await request.json();

    return {ok: data.success, raw: data};
  },
  getPackInstallCount({rootState, commit, dispatch}, id: number) {
    return Vue.http.get(`https://api.modpacks.ch/public/modpack/${id}`).then((res) => res.json()).then((data) => {
      return data.installs;
    })
  }
};
