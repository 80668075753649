import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {RootState} from './types';
import {auth} from './modules/auth';
import {modpacks} from './modules/modpacks';
import {cache} from './modules/cache';
import {admin} from './modules/admin';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const modules = {
  auth,
  modpacks,
  cache,
  admin,
}

const moduleKeys = Object.keys(modules);

// Separate the session state & local state to keep the user logged in whilst allowing cache to die and be recreated
const vuexPersistAuth = new VuexPersistence<RootState>({
  key: process.env.NODE_ENV === "production" ? "curator-auth" : "curator-auth-dev",
  storage: window.localStorage,
  modules: ["auth"]
});

export const sessionCacheKey = process.env.NODE_ENV === "production" ? "curator-state" : "curator-state-dev";

const vuexPersistAll = new VuexPersistence<RootState>({
  key: sessionCacheKey,
  storage: window.sessionStorage,
  modules: moduleKeys.filter(e => e !== "auth")
});

const store: StoreOptions<RootState> = {
  // @ts-ignore
  state: {
    version: '1.0.0',
  },
  modules,
  plugins: [vuexPersistAuth.plugin, vuexPersistAll.plugin],
};


export default new Vuex.Store<RootState>(store);
